$primary-color: #2b80ff;
$primary-color-back: #dbeaff;
$primary-linear-one: #4a77ee;
$primary-linear-two: #74acff;

.area {
  .row-a1 {
    padding: 100px 0;
    padding-bottom: 20px;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }

    .con {
      .list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .li {
          position: relative;
          width: 30%;
          overflow: hidden;
          margin-bottom: 50px;
          &:hover {
            .shade {
              top: 0;
            }
          }

          .pic {
            height: 280px;
            overflow: hidden;
            position: relative;

            img {
              position: absolute;
              top: 50%;
              left: 50%;
              height: 100%;
              transform: translate(-50%, -50%);
              width: 100%;
            }
          }

          .text {
            background-color: $primary-linear-two;
            font-size: 24px;
            text-align: center;
            padding: 10px 0;
            color: #fff;
          }

          .shade {
            position: absolute;
            top: 100%;
            background: rgba($color: #95b6db, $alpha: 0.8);
            color: #fff;
            width: 100%;
            height: 100%;
            transition: all 0.3s;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px;

            .tit {
              font-size: 24px;
              margin-bottom: 50px;
            }

            .txt {
              font-size: 16px;
            }
          }
        }
      }
    }
    .wrap {
      .item {
        box-sizing: border-box;
        min-height: 250px;
        width: 100%;
        margin-bottom: 30px;
        border-bottom: 1px solid rgba(128, 128, 128, 0.5);
        border-left: 1px solid rgba(128, 128, 128, 0.5);
        border-right: 1px solid rgba(128, 128, 128, 0.5);
        box-shadow: 0px 4px 4px rgba(128, 128, 128, 0.5);
        display: flex;
        .left {
          width: 250px;
          display: flex;
          flex-direction: column;
          // justify-content: center;
          align-items: center;
          position: relative;

          h5 {
            font-size: 20px;
            font-weight: 900;
            letter-spacing: 0px;
            line-height: 52.13px;
            color: rgba(42, 130, 228, 1);
            text-align: left;
            vertical-align: top;
          }
          img {
            position: absolute;
            width: 100%;
            height: 190px;
            bottom: 0;
            left: 0;
          }
        }
        .right {
          width: 100%;
          box-sizing: border-box;
          padding-top: 25px;
          padding-bottom: 30px;
          padding-right: 10px;
          display: flex;

          align-items: flex-start;
          .width23 {
            width: 20%;
          }
          .width31 {
            width: 35%;
          }

          .li {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 20px;
            h3 {
              font-size: 16px;
              font-weight: 500;
              letter-spacing: 2px;
              color: rgba(56, 56, 56, 1);
            }
            h6 {
              margin-top: 1px;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0px;
              line-height: 0px;
              color: rgba(166, 166, 166, 1);
              margin-bottom: 25px;
              width: max-content;
            }
            .desc {
              width: 100%;
              display: flex;

              .icon {
                margin-right: 5px;
                width: 10px;
                height: 10px;
                margin-top: 5px;
              }
              .p {
                font-size: 11px;
                color: #808080;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 769px) {
    .row-a1 {
      .title {
        margin-bottom: 100px;
      }

      .con {
        .list {
          flex-direction: column;
          .li {
            width: 50%;
            margin-bottom: 50px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .row-a1 {
      .title {
        margin-bottom: 100px;
      }
      .con {
        .list {
          .li {
            width: 100%;
          }
        }
      }
    }
  }
}
