$primary-color: #2b80ff;
$primary-color-back: #dbeaff;
$primary-linear-one: #4a77ee;
$primary-linear-two: #74acff;

$primary-col: #00baad;

.flair {

  .row-a1 {
    padding: 100px 0;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }

    .con-a1 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 100px;

      .left {
        width: 40%;

        .text {

          .tit {
            font-size: 24px;
            line-height: 100px;
            text-align: center;
          }

          .desc {
            color: #acacac;
            line-height: 35px;
            padding: 0 30px;
            text-indent: 30px;
          }
        }
      }

      .right {
        width: 55%;
        position: relative;

        .swiper-container-con-a1 {
          overflow: hidden;
        }

        .swiper-button-next-con-a1 {
          position: absolute;
          right: -60px;
          z-index: 99;
          top: 50%;
          width: 40px;
          background-color: #fff;
          height: 80px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;

          box-shadow: 0px 0px 3px #2b80ff;

          transform: translate(0, -50%);
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          img {
            transform: rotate(180deg);
            user-select: none;
          }

          &:hover {
            cursor: pointer;
          }
        }

        .swiper-button-prev-con-a1 {
          position: absolute;
          left: -60px;
          z-index: 999;
          top: 50%;
          width: 40px;
          background-color: #fff;
          height: 80px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;

          box-shadow: 0px 0px 3px #2b80ff;

          transform: translate(0, -50%);

          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          img {

            user-select: none;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .con-a2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .left {
        padding: 20px;
        width: 50%;

        .swiper-container-con-a2 {
          overflow: hidden;
        }

        .pic-box {
          position: relative;
          background: url("../../static/images/pic038.png") 0% 0% no-repeat;
          background-size: 100% 100%;
          width: 100%;
          height: 400px;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          .pic{
            width: 100%;
            overflow: hidden;
            margin: 10px;
            background-color: #fff;
            img{
              height: 100%;
            }
          }
        }
      }

      .right {
        width: 50%;

        .text {
          .tit {
            font-size: 24px;
            line-height: 100px;
            text-align: center;
          }

          .desc {
            color: #acacac;
            line-height: 35px;
            padding: 0 30px;
            text-indent: 30px;
          }
        }
      }
    }
  }


  @media only screen and (max-width: 1199px) {
    .row-a1 {
      .con-a1 {
        .right {
          .swiper-button-next-con-a1 {
            right: -50px;
          }

          .swiper-button-prev-con-a1 {
            left: -50px;
          }
        }
      }

      .con-a2 {
        .left {
          .pic-box {
            height: 350px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 959px) {
    .row-a1 {
      .con-a1 {
        flex-direction: column;

        .left {
          width: 100%;
          margin-bottom: 50px;
        }

        .right {
          margin: 0 auto;
          width: 460px;

          .item {
            width: 460px;
          }
        }
      }

      .con-a2 {
        flex-direction: column-reverse;

        .left {
          width: 100%;
          padding: 0;

          .swiper-container-con-a2 {
            width: 450px;
            margin: 0 auto;
          }

          .pic-box {
            height: 300px;
          }
        }

        .right {
          width: 100%;
          margin-bottom: 50px;
        }
      }
    }
  }

  @media only screen and (max-width: 769px) {
    .row-a1{
      .con-a1 {
        .right {
          .swiper-button-next-con-a1 {
            right: -30px;
            width: 20px;
            height: 40px;
          }
  
          .swiper-button-prev-con-a1 {
            left: -30px;
            width: 20px;
            height: 40px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .row-a1 {
      .title {
        margin-bottom: 100px;
      }

      .con-a1 {
        flex-direction: column;

        .left {
          width: 100%;
          margin-bottom: 50px;
        }

        .right {
          width: 300px;

          .item {
            width: 300px;
          }
        }
      }

      .con-a2 {
        .left {
          .swiper-container-con-a2 {
            width: 300px;
            margin: 0 auto;
          }

          .pic-box {
            height: 200px;
          }
        }

        .right {
          width: 100%;
          margin-bottom: 50px;
        }
      }
    }
  }































































}