$primary-color: #2b80ff;
$primary-color-back: #dbeaff;
$primary-linear-one: #4a77ee;
$primary-linear-two: #74acff;


.custom{
  .row-a1{
    padding: 100px 0;
    .title {
      margin-bottom: 50px;
      position: relative;
  
      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }
  
      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }
    .con{
      padding: 50px 0;
      background-color: #fafdff;
      box-shadow: 0 0 5px 1px #a6a6a6;
      border-radius: 5px;
      .tit{
        margin-bottom: 50px;
        text-align: center;
      }
      .ant-form{
        margin: 0 auto;
      }
      .submit{
        .ant-form-item-label{
          text-align: left !important;
        }
        .ant-form-item-control-input-content{
          display: flex ;
          justify-content: center ;
        }
      }
    }
  
  }

  @media only screen and (max-width: 500px) {
    .row-a1{
      .title{
        margin-bottom: 100px;
      }
      .con{

        .submit{
          .ant-col-offset-6{
            margin: 0;
          }
        }
      }
    }
  }




















}