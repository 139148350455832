$primary-color: #2b80ff;
$primary-color-back: #dbeaff;
$primary-linear-one: #4a77ee;
$primary-linear-two: #74acff;

.case {

  .row-a1 {
    padding: 100px 0;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }


    .con {
      
      .swiper-container-row-a1 {
        overflow: hidden;
        .swiper-wrapper{
          margin-bottom: 30px;
        }
        .swiper-pagination-row-a1{
          text-align: center;
        }
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          height: 420px;
          border-radius: 2px;
          box-shadow: 0 0 5px 1px #bfbfbf;
          overflow: hidden;
          .pic{
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
          }

          &:hover{
            cursor: pointer;
            .shade{
              top: 0;
            }
          }

          .text {
            padding: 20px;
          }

          .shade {
            position: absolute;
            top: 100%;
            width: 100%;
            height: 100%;
            transition: all .3s;
            background: rgba($color: #95b6db, $alpha: .8);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .txt{
              color: #fff;
              font-size: 24px;
              font-weight: bold;
              writing-mode:vertical-lr;
              letter-spacing: 10px;
            }
          }
        }
      }
    }

  }


  @media only screen and (max-width: 500px) {
    .row-a1{
      .title{
        margin-bottom: 100px;
      }
    }
  }


}