$primary-color: #2b80ff;
$primary-color-back: #dbeaff;
$primary-linear-one: #4a77ee;
$primary-linear-two: #74acff;

.productuse {

  .row-a1 {
    padding: 100px 0;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }

    .con {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .nav {
        width: 25%;
        padding: 30px 30px 30px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 300px;
        border-right: solid 1px #333;

        .tit {
          font-size: 24px;
          color: $primary-color;
          font-weight: bold;
          text-align: center;
          margin-bottom: 50px;
        }

        .btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-content: center;
          width: 100px;
          line-height: 40px;
          height: 40px;
          background-color: $primary-color;
          color: #fff;
          border-radius: 5px;

          .icon {
            margin-right: 5px;
            width: 10px;
            position: relative;

            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      .box {
        width: 70%;
        background-color: #cfe8fc;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 50px;
        border-radius: 5px;

        .left,
        .right {
          width: 50%;
        }

        .left {
          .tit {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 50px;
          }

          .desc {
            font-size: 12px;
          }

          .col{
            display: none;
          }
        }

        .right {
          .tit {
            font-size: 20px;
            color: #2698f3;
            margin-bottom: 50px;
          }
        }
      }
    }
  }


  @media only screen and (max-width: 959px) {
    .row-a1 {
      .con {
        .box {
          padding: 20px;
        }
      }
    }
  }

  @media only screen and (max-width: 769px) {
    .row-a1 {
      .con {
        .nav {
          display: none;
        }

        .box {
          width: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .row-a1 {
      .title {
        margin-bottom: 100px;
      }

      .con {
        .box {
          width: 100%;
          flex-direction: column;

          .left,
          .right {
            width: 100%;
          }

          .left {
            margin-bottom: 50px;
            .row{
              display: none;
            }

            .col{
              display: block;
            }
          }
        }
      }
    }
  }






}