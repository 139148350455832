$primary-color: #2b80ff;
$primary-color-back: #dbeaff;
$primary-linear-one: #4a77ee;
$primary-linear-two: #74acff;

.develop {

  .row-a1 {
    padding: 100px 0;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }

    .con {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 100px 0;

      .list {
        display: flex;
        flex-direction: column;

        .li {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          background-color: #eaf3fd;
          border-radius: 10px;
          margin-bottom: 50px;
          .pic {
            width: 300px;
            min-width: 300px;
            img{
              height: 100%;
              width: 100%;
            }
          }

          .text {
            padding: 20px 50px;
            .desc {
              color: #A6A6A6;
              text-indent: 30px;
            }
          }
        }
      }
    }

  }

  @media only screen and (max-width: 965px) {
    .row-a1{
      .con{
        .list{
          .li{
            .pic{
              width: auto;
              min-width: 300px;
            }
            .text{
              padding: 10px 30px;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 769px) {
    .row-a1{
      .con{
        .list{
          .li{
            .pic{
              width: auto;
              min-width: 280px;
            }
            .text{
              padding: 10px 30px;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 500px) {
    .row-a1{
      .con{
        .list{
          .li{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            .pic{
              width: 100%;
              margin-bottom: 20px;
            }
            .text{
              padding: 10px 30px;
            }
          }
        }
      }
    }
  }
}