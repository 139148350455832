$primary-color: #2b80ff;
$primary-color-back: #dbeaff;
$primary-linear-one: #4a77ee;
$primary-linear-two: #74acff;

.company {
  .row-a1 {
    padding: 100px 0;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }

    .con {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .content {
        width: calc(100% - 450px);
        padding-right: 50px;

        &>.tit {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 20px;
        }

        .date {
          font-size: 12px;
          margin-bottom: 20px;
        }

        .pic {
          text-align: center;
          margin-bottom: 20px;
        }

        .desc {
          text-indent: 30px;
          line-height: 35px;
          p{
            margin-bottom: 50px;
          }
        }
      }

      .news {
        width: 450px;
        padding-left: 50px;

        .new {
          margin-bottom: 100px;

          &>.tit {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 20px;
          }

          .list {
            height: 420px;
            overflow-y: auto;

            &::-webkit-scrollbar {
              width: 4px;
              background-color: #fafafa;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #cccccc;
            }

            .li {
              margin-bottom: 20px;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              height: 120px;

              &:hover {
                cursor: pointer;
              }

              .pic {
                margin-right: 20px;
                min-width: 120px;
                text-align: center;
                position: relative;
                overflow: hidden;
                height: 100%;
                width: 120px;

                img {
                  position: absolute;
                  border-radius: 4px;
                  top: 50%;
                  left: 50%;
                  height: 100%;
                  transform: translate(-50%, -50%);
                }
              }

              .text {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                padding-right: 10px;
                height: 100%;

                .tit {
                  font-weight: bold;
                }

                .txt {
                  font-size: 12px;
                  margin-bottom: 5px;
                }

                a {
                  width: 100%;
                  text-align: right;
                  font-size: 12px;
                  color: #2b80ff;
                }

              }
            }
          }
        }


      }
    }

  }

  @media only screen and (max-width: 960px) {
    .row-a1 {
      .con {
        display: flex;
        flex-direction: column;

        .content {
          width: 100%;
          padding: 0;
          margin-bottom: 100px;
        }

        .news {
          width: 100%;
          padding: 0;

          .new {}
        }
      }
    }
  }


  @media only screen and (max-width: 500px) {
    .row-a1 {
      .title {
        margin-bottom: 100px;
      }
    }
  }



































}