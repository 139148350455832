$primary-color: #2b80ff;
$primary-color-back: #eaf1f9;
$primary-a-color: #2a82e4;

.home {
  .banner {
    height: 500px;
    overflow: hidden;

    .pic {
      height: 500px;
      position: relative;
      background-color: #fff;

      img {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .swiper-pagination-bullet {
      opacity: 1;
      background-color: #ffffff;
      border: solid 2px $primary-color;
    }

    .swiper-pagination-bullet-active {
      background-color: $primary-color;
    }

    .con {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: flex-end;

      .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        float: right;
        width: 300px;

        .title {
          text-align: center;
          color: #fff;

          .tit {
            font-size: 50px;
            animation-duration: 2s;
          }

          .txt {
            animation-duration: 2s;
          }
        }

        .desc {
          color: #fff;
          font-size: 28px;
          animation-duration: 2s;
          animation-delay: 2s;
          text-align: center;
        }
      }
    }
  }

  .row-a1 {
    padding: 100px 0;

    .con {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .left {
        width: 40%;
      }

      .right {
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 100px;

        .tit {
          font-size: 24px;
          margin-bottom: 20px;
        }

        .desc {
          font-size: 16px;
          text-indent: 30px;
          margin-bottom: 20px;
        }

        .btn {
          width: 100%;
          text-align: right;

          a {
            color: $primary-a-color;
          }
        }
      }
    }
  }

  .row-a2 {
    padding: 100px 0;
    background-color: $primary-color-back;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }

    .con {

      position: relative;
      padding: 0 80px;

      .swiper-container-row-a2 {
        position: relative;
        overflow: hidden;

      }

      .swiper-button-next-row-a2 {
        position: absolute;
        right: 0px;
        z-index: 999;
        top: 50%;
        width: 40px;
        background-color: #fff;
        height: 80px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;

        box-shadow: 0px 0px 3px #2b80ff;

        transform: translate(0, -50%);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        img {
          transform: rotate(180deg);
          user-select: none;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .swiper-button-prev-row-a2 {
        position: absolute;
        left: 0px;
        z-index: 999;
        top: 50%;
        width: 40px;
        background-color: #fff;
        height: 80px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        box-shadow: 0px 0px 3px #2b80ff;

        transform: translate(0, -50%);

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        img {

          user-select: none;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .item {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        &:hover {
          cursor: pointer;
        }

        .pic {
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          overflow: hidden;
          width: 100%;
          height: 180px;
          text-align: center;

          img {
            height: 100%;
          }
        }

        .text {
          padding: 20px;

          .date {
            margin-bottom: 10px;
          }

          .desc {
            color: #1b508c;
            font-weight: bold;
            height: 80px;
          }

          .btn {
            width: 80px;
            text-align: center;
            background-color: $primary-a-color;
            color: #fff;
            font-size: 12px;
            line-height: 30px;
            border-radius: 3px;
          }
        }
      }
    }
  }

  .row-a3 {
    padding: 100px 0;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }

    .con {
      .swiper-pagination-row-a3 {
        width: 60px;
        margin: 0 auto;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      position: relative;

      .swiper-container-row-a3 {
        position: relative;
        overflow: hidden;
        padding: 35px 5px;
      }

      .swiper-slide-active {
        margin-top: -30px;
      }

      .item {
        margin-bottom: 10px;
        border-radius: 5px;

        .pic {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          overflow: hidden;
        }

        .text {
          padding: 30px;

          .tit {
            color: $primary-color ;
            text-align: center;
            margin-bottom: 30px;
          }

          .desc {
            color: #999999;
          }
        }

        box-shadow: 0px 0px 5px 1px #c3c3c3;
      }
    }

  }

  @media only screen and (max-width: 1199px) {
    .banner {
      height: 500px;

      .pic {
        height: 500px;

        img {
          height: 500px;
        }
      }
    }

    .row-a2 {
      .con {
        .swiper-button-next-row-a2 {
          right: 0;
        }

        .swiper-button-prev-row-a2 {
          left: 0;
        }

        .swiper-container-row-a2 {
          margin: 0 50px;
        }

        .item {
          .pic {
            height: 160px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 959px) {
    .banner {
      height: 350px;

      .pic {
        height: 350px;

        img {
          height: 350px;
        }
      }

      .con {
        .box {
          .title {
            .tit {
              font-size: 45px;
            }
          }

          .desc {
            font-size: 24px;
          }
        }
      }
    }

    .row-a1 {
      .con {
        flex-direction: column;

        .left {
          width: 100%;
          margin-bottom: 20px;
        }

        .right {
          width: 100%;
        }
      }
    }

    .row-a2 {
      .con {
        .item {
          .pic {
            height: 120px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 769px) {
    .banner {
      height: 300px;

      .pic {
        height: 300px;

        img {
          height: 300px;
        }
      }

      .con {
        .box {
          .title {
            .tit {
              font-size: 35px;
            }
          }

          .desc {
            font-size: 20px;
          }
        }
      }

    }

    .row-a1 {
      .con {
        .right {
          padding: 0 50px;
        }
      }
    }

    .row-a2 {
      .con {
        padding: 0;

        .swiper-button-next-row-a2 {
          width: 20px;
          height: 40px;
        }

        .swiper-button-prev-row-a2 {
          width: 20px;
          height: 40px;
        }
        .item{
          .pic{
            height: 160px;
          }
        }
      }
    }

    .row-a3 {
      .title {
        margin-bottom: 100px;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .banner {
      height: 200px;

      .pic {
        height: 200px;

        img {
          height: 200px;
        }
      }

      .con {
        .box {
          .title {
            .tit {
              font-size: 26px;
            }
          }

          .desc {
            font-size: 16px;
          }
        }
      }

    }

    .row-a1 {
      .con {
        .right {
          padding: 0 20px;
        }
      }
    }

    .row-a2 {
      .title {
        margin-bottom: 100px;
      }
      .con{
        .item{
          .pic{
            height: 120px;
          }
        }
      }
    }

  }
}