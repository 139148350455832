$primary-color: #2b80ff;
$primary-color-back: #dbeaff;
$primary-linear-one: #4a77ee;
$primary-linear-two: #74acff;

.productvideo {

  .row-a1 {
    padding: 100px 0;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }

    .con {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .nav {
        width: 25%;
        padding: 30px 30px 30px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 300px;
        border-right: solid 1px #333;

        .tit {
          font-size: 24px;
          color: $primary-color;
          font-weight: bold;
          text-align: center;
          margin-bottom: 50px;
        }

        .btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-content: center;
          width: 100px;
          line-height: 40px;
          height: 40px;
          background-color: $primary-color;
          color: #fff;
          border-radius: 5px;

          .icon {
            margin-right: 5px;
            width: 10px;
            position: relative;

            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      .list {
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;

        .li {
          width: 300px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .video {
            text-align: center;
            position: relative;
            width: 100%;
            margin-bottom: 50px;

            video {
              width: 300px;
            }

            .pic {
              height: 100%;
              width: 100%;
              background-color: #fff;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 9;
              overflow: hidden;
              img {
                width: 100%;
              }
            }

            .icon {
              z-index: 10;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 45px;
              height: 45px;
              text-align: center;
              line-height: 50px;
              background-color: #fff;
              border-radius: 50%;

              &:hover {
                cursor: pointer;
              }

              img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 10px;
              }
            }

            &.play {
              .pic {
                display: none;
              }

              .icon {
                display: none;
              }
            }
          }

          .tit {
            font-size: 18px;
            color: #000;
            margin-bottom: 50px;
            text-align: center;
            height: 85px;
          }

          .btn {
            &:hover{
              cursor: pointer;
            }
            width: 120px;
            line-height: 35px;
            border-radius: 5px;
            background-color: #000;
            color: #fff;
            text-align: center;
          }
        }
      }
    }
  }


  @media only screen and (max-width: 959px) {
    .row-a1 {
      .con {
        .nav{
          display: none;
        }
        .list{
          width: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: 769px) {
    .row-a1 {
      .con {
        .list{
          flex-direction: column;
          .li{
            margin-bottom: 50px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .row-a1 {
      .title {
        margin-bottom: 100px;
      }

    }
  }






}