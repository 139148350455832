$primary-color: #2b80ff;
$primary-color-back: #dbeaff;
$primary-linear-one: #4a77ee;
$primary-linear-two: #74acff;

.product-info {

  .row-a1 {
    padding: 100px 0;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }

    .con {
      .btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        width: 100px;
        line-height: 40px;
        height: 40px;
        background-color: $primary-color;
        color: #fff;
        border-radius: 5px;
        margin-bottom: 50px;

        .icon {
          margin-right: 5px;
          width: 10px;
          position: relative;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &:hover{
          cursor: pointer;
        }
      }
    }


  }

  @media only screen and (max-width: 500px) {
    .row-a1 {
      .title {
        margin-bottom: 100px;
      }
    }
  }




}