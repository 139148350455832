$primary-color: #2b80ff;
$primary-color-back: #dbeaff;
$primary-linear-one: #4a77ee;
$primary-linear-two: #74acff;


.illustrate {
  .row-a1 {
    padding: 100px 0;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }

    .con {
      .list {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
        .li {
          width: 250px;
          height: 350px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          border-radius: 5px;
          padding: 50px;
          margin-bottom: 50px;
          border: solid 1px #dfdfdf;
          transition: all .3s;
          &:hover{
            cursor: pointer;
            background-color: #2a82e4;
            .tit{
              color: #fff;
            }
            .btn{
              background-color: #fff;
              color: #2a82e4;
            }
          }
          .tit{
            font-weight: bold;
            font-size: 24px;
            color: #2a82e4;
            text-align: center;
            transition: all .3s;
          }
          .btn{
            width: 120px;
            line-height: 35px;
            background-color: #5a92e6;
            color: #fff;
            text-align: center;
            border-radius: 5px;
            font-size: 12px;
            transition: all .3s;
          }
        }
      }
    }


  }

  @media only screen and (max-width: 959px) {
    .row-a1 {}
  }

  @media only screen and (max-width: 769px) {
    .row-a1 {}
  }

  @media only screen and (max-width: 500px) {
    .row-a1 {
      .title {
        margin-bottom: 100px;
      }
    }
  }
}