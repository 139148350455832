$primary-color: #2b80ff;
$primary-color-back: #dbeaff;
$primary-linear-one: #4a77ee;
$primary-linear-two: #74acff;

.regard {
  .row-a1 {
    padding: 100px 0;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }

    .con {
      .list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .li {
          width: 30%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 50px;
          box-shadow: 0 0 5px 1px #a6a6a6;
          border-radius: 5px;
          .pic {
            text-align: center;
          }

          .text {
            padding: 50px 0;
            text-align: center;
            font-weight: bold;
          }
        }
      }
      .banner {
        width: 100%;
        height: 400px;
        position: relative;
        box-sizing: border-box;
        padding-top: 50px;
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        .main-desc {
          position: relative;
          padding-left: 130px;
          h3 {
            color: white;
            font-weight: bold;
            font-size: 30px;
          }
          .marTop10 {
            margin-top: 10px;
          }
          h6 {
            color: white;
          }
          .link {
            margin-top: 90px;
            color: white;
            width: 120px;
            height: 40px;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid white;
          }
          .link:hover {
            cursor: pointer;
          }
        }
      }
      .info {
        width: 100%;
        background: #dbe9fc;

        box-sizing: border-box;
        padding: 30px 130px;
        h2 {
          color: #383838;
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 30px;
        }
        p {
          font-size: 12px;
          color: black;
          margin-top: 12px;
        }
        .tip {
          font-weight: bold;
          margin-top: 40px;
          color: black;
        }
        .course {
          background: white;
          margin-top: 50px;
          border-radius: 20px;
          width: 100%;

          box-sizing: border-box;
          .head {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 20px;
            box-sizing: border-box;
            h3 {
              font-weight: bold;
              font-size: 18px;
            }
            h6 {
              color: #d4d4d4;
              font-size: 14px;
            }
          }
          .foot {
            width: 100%;
            padding: 20px;
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;

            .item {
              width: 24%;
              display: flex;
              flex-direction: column;
              img {
                width: 100%;
                height: 150px;
              }
              h3 {
                flex: 1;
                display: block;
                font-weight: bold;
                font-size: 14px;
                width: 100%;
                border-left: 1px solid #f4f4f4;
                border-right: 1px solid #f4f4f4;
                padding: 10px 15px;
              }
            }
          }
        }
      }
      .solt {
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        img {
          width: 600px;
        }
      }
    }
  }

  @media only screen and (max-width: 959px) {
    .row-a1 {
      .con {
        .list {
          .li {
          }
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .row-a1 {
      .title {
        margin-bottom: 100px;
      }
      .con {
        .list {
          flex-direction: column;
          .li {
            width: 100%;
            margin-bottom: 50px;
          }
        }
      }
    }
  }
}
