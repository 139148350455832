$primary-color: #2b80ff;
$primary-color-back: #eaf1f9;
$primary-a-color: #2a82e4;

.brief {
  .banner {}

  .row-a1 {
    padding: 100px 0;

    .title {
      text-align: center;
      font-size: 28px;
      margin-bottom: 50px;
      font-weight: bold;
    }

    .con {
      .desc {
        display: flex;
        flex-direction: column;
        padding: 0px 50px;

        p {
          text-indent: 30px;
          line-height: 45px;
        }
      }
    }
  }
  @media only screen and (max-width: 969px) {
    .row-a1 {
      .con {
        .desc {
          padding: 0 30px;
        }
      }
    }
  }

  @media only screen and (max-width: 769px) {
    .row-a1 {
      .con {
        .desc {
          padding: 0 20px;
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .row-a1 {
      .con {
        .desc {
          padding: 0;
        }
      }
    }
  }





}