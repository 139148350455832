.error {
  padding: 50px 0;

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ff0000;

    .tit {
      font-size: 28px;
      font-weight: bold;
    }
  }
}