$primary-color: #2b80ff;
$primary-color-back: #dbeaff;
$primary-linear-one: #4a77ee;
$primary-linear-two: #74acff;

.history {
  .row-a1 {
    padding: 100px 0;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }

    .con {
      .list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        .li {
          display: flex;
          flex-direction: column;
          width: 19%;
          .name {
            font-size: 16px;
            font-weight: bold;
            color: #1054a1;
            text-align: center;
          }
          .year {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .text {
              margin-right: 10px;
              font-size: 24px;
            }
            .icon {
              width: 15px;
              img {
                width: 100%;
              }
            }
          }
          .pic {
            border-top: solid 20px #1054a1;
            border-left: solid 20px #1054a1;
            padding: 10px;
            margin-bottom: 20px;
          }
          .desc {
            text-align: center;
          }
          &:nth-child(1) {
            margin-top: 500px;
          }
          &:nth-child(2) {
            margin-top: 400px;
          }
          &:nth-child(3) {
            margin-top: 300px;
          }
          &:nth-child(4) {
            margin-top: 200px;
          }
          &:nth-child(5) {
            margin-top: 100px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 965px) {
    .row-a1 {
      .con {
        .list {
          flex-wrap: wrap;
          justify-content: space-around;
          .li {
            width: 33%;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 500px) {
    .row-a1 {
      .con {
        .list {
          flex-wrap: wrap;
          .li {
            .name {
              text-align: center;
            }
            width: 95%;
            &:nth-child(1) {
              margin-top: 50px;
            }
            &:nth-child(2) {
              margin-top: 50px;
            }
            &:nth-child(3) {
              margin-top: 50px;
            }
            &:nth-child(4) {
              margin-top: 50px;
            }
            &:nth-child(5) {
              margin-top: 50px;
            }
          }
        }
      }
    }
  }
}
