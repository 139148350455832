$primary-color: #2b80ff;
$primary-color-back: #dbeaff;
$primary-linear-one: #4a77ee;
$primary-linear-two: #74acff;

.culture {
  .row-a1 {
    padding: 100px 0;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }

    .list {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      .li {
        width: 276px;
        border: dashed 1px #000000;
        border-radius: 5px;
        padding: 20px;
        height: 420px;

        .con-a1 {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        .icons {
          position: relative;
          width: 100px;
          height: 50px;

          img {
            width: 100%;
          }

          .icon1 {
            position: absolute;
            left: 0px;
            top: 50%;
            transform: translate(0, -50%);
            transition: all 1s;
          }

          .icon2 {
            position: absolute;
            left: 37px;
            top: 50%;
            transform: translate(0, -50%);
            opacity: 0;
          }

          .icon3 {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            transition: all 1s;
          }
        }

        .icon-box2 {
          .icon1 {
            left: 0px;
          }

          .icon2 {
            left: 38px;
          }

          .icon3 {
            right: 5px;
          }
        }

        .icon-box3 {
          .icon1 {
            left: 0px;
          }

          .icon2 {
            left: 44px;
          }

          .icon3 {
            right: 8px;
          }
        }

        .text {
          text-align: center;

          .tit {
            font-size: 26px;
          }

          .txt {
            font-size: 10px;
          }
        }

        .con-a2 {
          position: relative;
          height: 20px;
          margin-bottom: 50px;

          .anima {
            width: 80px;
            height: 3px;
            background-color: #00baad;
            text-align: right;
            position: absolute;
            right: -20px;
            top: 50%;
            transform: translate(0, -50%);

            transition: all .5s;
          }
        }

        .con-a3 {
          text-align: center;
          line-height: 50px;
          font-size: 20px;
        }

        &:hover {

          .icons {
            .icon1 {
              transform: translate(36px, -50%);
            }

            .icon2 {
              opacity: 1;
              transition-duration: .5s;
              transition-delay: 1s;
              transition-timing-function: linear;
            }

            .icon3 {
              transform: translate(-38px, -50%) rotateY(180deg);
            }
          }

          .icon-box3 {
            .icon1 {
              transform: translate(42px, -50%);
            }

            .icon2 {
              opacity: 1;
            }

            .icon3 {
              transform: translate(-30px, -50%) rotateY(180deg);
            }
          }

          .icon-box4 {
            .icon1 {
              transform: translate(35px, -50%);
            }

            .icon2 {
              opacity: 1;
            }

            .icon3 {
              transform: translate(-42px, -50%) rotateY(180deg);
            }
          }

          .anima {
            width: 140px;
          }
        }

      }
    }

  }

  @media only screen and (max-width: 1199px) {
    .row-a1 {
      .con {
        .list {
          flex-wrap: wrap;
          justify-content: space-around;

          .li {
            width: 276px;
            height: 500px;
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 959px) {
    .row-a1 {
      .con {
        .list {
          .li {
            width: 276px;
            height: 480px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 769px) {
    .row-a1 {
      .con {
        .list {
          .li {
            width: 276px;
            height: 400px;

            .con-a3 {
              font-size: 16px;
              line-height: 35px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .row-a1 {
      .title{
        margin-bottom: 100px;
      }
      .con {
        .list {
          .li {
            width: 276px;
            height: 400px;

            .con-a3 {
              font-size: 16px;
              line-height: 35px;
            }
          }
        }
      }
    }
  }

















}