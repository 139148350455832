$primary-color: #2b80ff;
$primary-color-back: #dbeaff;
$primary-linear-one: #4a77ee;
$primary-linear-two: #74acff;

$primary-col: #00baad;

.core {
  .row-a1 {
    padding: 100px 0;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 30px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: $primary-color-back;
        font-weight: bolder;
        user-select: none;
      }
    }

    .con-a1 {
      background-color: #f0f7ff;

      .list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 50px 0;

        .li {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 50%;
          padding: 50px 0;
          .set-type {
            display: flex;
            flex-direction: row;
            width: 80%;
          }

          .icon {
            // width: 20px;
            // text-align: center;
            // margin-right: 20px;
            img{
              width: 20px;
              text-align: center;
              margin-right: 20px;
          
            }
          }

          .txt {
            font-size: 16px;
            font-weight: bold;
            flex: 1;
          }
          .desc {
            margin-top: 40px;
            width: 80%;
            margin-left: 13px;
            .item {
              display: flex;
              .sub-icon {
                width: 15px;
                height: 15px;
                margin-right: 10px;
              }
              .sub-title {
                font-weight: 900;
                color: #808080;
              }
              .sub-desc {
                flex: 1;
              }
            }
          }
        }
      }
    }

    .con-a2 {
      padding: 50px 0;

      .texts {
        padding: 50px 0;

        .text {
          display: flex;
          float: left;
          flex-direction: column;
          justify-content: center;

          .txts {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-end;
            margin-bottom: 10px;

            .font {
              font-size: 36px;
            }

            .font-w {
              font-size: 38px;
              font-weight: bold;
            }

            .txt {
              font-size: 25px;
              line-height: 50px;
              margin-right: 10px;
            }
          }

          .line {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .one {
              width: 100px;
              height: 4px;
              background-color: #ffa155;
            }

            .two {
              width: 100px;
              height: 4px;
              background-color: #ffd3af;
            }

            .three {
              width: 100px;
              height: 4px;
              background-color: #444cfc;
            }
          }

          &:nth-child(2) {
            float: right;
            padding-top: 80px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 769px) {
    .row-a1 {
      .con-a1 {
        .list {
          flex-direction: column;
        }
      }

      .con-a2 {
        .texts {
          .text {
            .txts {
              .font {
                font-size: 32px;
              }
              .font-w {
                font-size: 36px;
              }
              .txt {
                font-size: 18px;
                line-height: 36px;
              }
            }

            &:nth-child(2) {
              padding-top: 30px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .row-a1 {
      .title {
        margin-bottom: 100px;
      }

      .con-a2 {
        .texts {
          .text {
            .txts {
              .font-w {
                font-size: 24px;
              }
              .font {
                font-size: 18px;
              }
              .txt {
                font-size: 12px;
                line-height: 25px;
              }
            }

            &:nth-child(2) {
              padding-top: 30px;
            }
          }
        }
      }
    }
  }
}
