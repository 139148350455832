$primary-color: #2b80ff;
$primary-color-back: #eaf1f9;
$primary-a-color: #2a82e4;

.build {
  .row-a1 {
    padding: 100px 0;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }

    .con {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 100px 0;

      .navs {
        display: flex;
        flex-direction: column;

        .nav {
          width: 120px;
          line-height: 50px;
          text-align: center;
          margin-bottom: 20px;
          background-color: #cddef7;
          border-radius: 5px;

          &:hover {
            cursor: pointer;
          }

          &.current {
            background-color: #1365c2;
            color: #fff;
          }
        }
      }

      .tree {
        width: calc( 100% - 120px);
        display: flex;
        flex-direction: row;
        justify-content: center;

        .OrgChart-module_orgChartContainer__x-d1w table td .node {
          background: #5a92e6;
          border-radius: 5px;
          color: #fff;
          margin: 0 35px;
        }
      }
    }
  }

  @media only screen and (max-width: 769px) {
    .row-a1 {
      .con {
        flex-direction: column;

        .navs {
          flex-direction: row;
          margin-bottom: 20px;

          .nav {
            margin-right: 10px;
          }
        }
        .tree{
          .OrgChart-module_orgChartContainer__x-d1w table td .node {
            margin: 0 5px;
          }
        }
      }
    }
  }
}