$primary-color: #2b80ff;
$primary-color-back: #dbeaff;
$primary-linear-one: #4a77ee;
$primary-linear-two: #74acff;


.terminal {
  .row-a1 {
    padding: 100px 0;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }

    .con {
      background-color: #cfe8fc;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 100px;
      border-radius: 5px;

      .left,
      .right {
        width: 50%;
      }

      .left {
        .tit {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 50px;
        }

        .desc {
          font-size: 12px;
        }

        .col{
          display: none;
        }
      }

      .right {
        .tit {
          font-size: 20px;
          color: #2698f3;
          margin-bottom: 50px;
        }

        .email {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          background-color: #fff;
          padding: 10px;
          border-radius: 5px;
          margin-bottom: 50px;

          .icon {
            width: 20px;
            margin-right: 10px;
          }
        }

        .btn {
          width: 120px;
          background-color: #292d3f;
          color: #fff;
          text-align: center;
          line-height: 45px;
          border-radius: 5px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }


  }

  @media only screen and (max-width: 959px) {
    .row-a1 {
      .con {
        padding: 20px;
      }
    }
  }

  @media only screen and (max-width: 769px) {
    .row-a1 {
      .con {
        padding: 10px;
        justify-content: space-between;

        .left {
          width: 35%;
        }

        .right {
          width: 60%;
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .row-a1 {
      .title {
        margin-bottom: 100px;
      }

      .con {
        flex-direction: column;

        .left,
        .right {
          width: 100%;
        }

        .left {
          margin-bottom: 50px;

          .row{
            display: none;
          }

          .col{
            display: block;
          }
        }
      }
    }
  }




}