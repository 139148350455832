$primary-color: #2b80ff;
$primary-color-back: #dbeaff;
$primary-linear-one: #4a77ee;
$primary-linear-two: #74acff;

.product-center {

  .row-a1 {
    padding: 100px 0;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }

    .con {
      .list {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        .li {
          width: 23%;
          margin-right: 2%;
          margin-bottom: 30px;
          &:nth-child(4n) {
            margin-right: 0px;
          }

          height: 420px;
          display: flex;
          flex-direction: column;
          align-items: center;
          box-shadow: 0 0 5px 1px #b2b2b2;
          border-radius: 2px;
          transition: all .3s;

          &:hover {
            cursor: pointer;
            margin-top: -50px;
          }

          .pic {
            width: 100%;
            height: 200px;
            overflow: hidden;
            text-align: center;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            position: relative;
            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
              width: 100%;
              height: 200px;
            }
          }

          .text {
            padding: 20px;

            .tit {
              font-size: 16px;
              color: #2a82e4;
              text-align: center;
              margin-bottom: 20px;
            }

            .desc {
              text-indent: 30px;
              color: #999999;
            }
          }
        }
      }
    }

  }


  @media only screen and (max-width: 960px) {
    .row-a1 {
      .title{
        margin-bottom: 100px;
      }
      .con {
        .list {
          flex-wrap: wrap;
          .li {
            width: 45%;
            height: 450px;
            margin-right: 5%;
            margin-bottom: 100px;
            &:nth-child(2n){
              margin-right: 0px;
            }
            .pic{
              height: 240px;
              img{
                height: 240px;
              }
            }
          }

        }
      }
    }
  }
  @media only screen and (max-width: 769px) {
    .row-a1 {
      .title{
        margin-bottom: 100px;
      }
      .con {
        .list {
          flex-wrap: wrap;
          .li {
            height: 500px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 500px) {
    .row-a1 {
      .con {
        .list {
          align-items: center;
          flex-direction: column;
          .li {
            width: 100%;
            margin: 0px;
            margin-bottom: 100px;
            height: auto;
            &:hover{
              margin-top: -20px;
            }
          }
        }
      }
    }
  }




















}