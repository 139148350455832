@media only screen and (max-width: 0px) {}

@media only screen and (max-width: 1800px) {}

@media only screen and (max-width: 1600px) {}

@media only screen and (max-width: 1500px) {}

@media only screen and (max-width: 1350px) {}

@media only screen and (max-width: 1199px) {
    .wrap {
        width: 95%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 959px) {}

@media only screen and (max-width: 769px) {

}

@media only screen and (max-width: 500px) {

}

@media only screen and (max-width: 350px) {}