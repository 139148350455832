$primary-color: #2b80ff;
$primary-color-back: #dbeaff;
$primary-linear-one: #4a77ee;
$primary-linear-two: #74acff;

.invest {

  .row-a1 {
    padding: 100px 0;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }
    .con{
      height: 300px;
    }
    
  }
  
}