$primary-color: #2b80ff;
$primary-color-back: #dbeaff;
$primary-linear-one: #4a77ee;
$primary-linear-two: #74acff;

.product-type {

  .row-a1 {
    padding: 100px 0;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }

    .con {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .nav {
        width: 25%;
        padding: 30px 30px 30px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 420px;
        border-right: solid 1px #333;

        .tit {
          font-size: 24px;
          color: $primary-color;
          font-weight: bold;
          text-align: center;
          margin-bottom: 50px;
        }

        .desc {
          text-indent: 30px;
        }

        .btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-content: center;
          width: 100px;
          line-height: 40px;
          height: 40px;
          background-color: $primary-color;
          color: #fff;
          border-radius: 5px;

          .icon {
            margin-right: 5px;
            width: 10px;
            position: relative;

            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          &:hover{
            cursor: pointer;
          }
        }
      }

      .list {
  
       
        width: 75%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;

        padding-left: 30px;

        &>div:nth-child(3n) {
          margin-right: 0px;
        }
        &>div{
          margin-bottom: 20px;
        }
        .li {
          width: 31%;
          margin-right: 2%;
          height: 420px;
          display: flex;
          flex-direction: column;
          align-items: center;
          box-shadow: 0 0 5px 1px #b2b2b2;
          border-radius: 2px;
          transition: all .3s;

          &:hover {
            cursor: pointer;
          }

          .pic {
            width: 100%;
            height: 200px;
            overflow: hidden;
            text-align: center;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            position: relative;

            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              height: 200px;
            }
          }

          .text {
            padding: 20px;

            .tit {
              font-size: 16px;
              color: #2a82e4;
              text-align: center;
              margin-bottom: 20px;
            }

            .desc {
              text-indent: 30px;
              color: #999999;
            }
          }
        }

        .lii {
          width: 95%;
          padding: 0 20px;
          margin-right: 2%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          height: 280px;

          box-shadow: 0 0 5px 1px #b2b2b2;
          border-radius: 2px;
          transition: all .3s;

          &:hover {
            cursor: pointer;
          }

          .pic {
            width: 50%;
            overflow: hidden;
            height: 100%;
            text-align: center;
            img{
              height: 100%;
            }
          }

          .text {
            width: 50%;
            padding: 30px 10px;
            .tit {
              font-size: 18px;
              color: #2a82e4;
              text-align: center;
              margin-bottom: 20px;
            }

            .desc {
              text-indent: 30px;
              color: #999999;
            }
          }
        }
      }
    }

  }


  @media only screen and (max-width: 960px) {
    .row-a1 {
      .con {
        .nav {
          width: 50%;
        }

        .list {
          flex-wrap: wrap;
          width: 50%;

          .li {
            width: 95%;
            height: 450px;
            margin: 0;
            margin-bottom: 100px;

            .pic {
              height: 240px;

              img {
                height: 240px;
              }
            }
          }
          .lii {
            width: 95%;
            height: 450px;
            margin: 0;
            margin-bottom: 100px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 769px) {
    .row-a1 {

      .con {

        .list {
          flex-wrap: wrap;

          .li {
            height: 450px;
          }
          .lii {
            height: 450px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .row-a1 {
      .title {
        margin-bottom: 100px;
      }
      .con {
        flex-direction: column;

        .nav {
          width: 100%;
          padding: 30px;
          margin: 0;
          margin-bottom: 100px;
          border: none;
        }

        .list {
          align-items: center;
          flex-direction: column;
          align-items: center;
          width: 100%;
          padding: 0;

          .li {
            width: 95%;
            margin: 0px;
            margin-bottom: 100px;
            height: auto;

            &:hover {
              margin-top: -20px;
            }
          }
          .lii {
            width: 95%;
            margin: 0px;
            margin-bottom: 100px;
            height: auto;

            &:hover {
              margin-top: -20px;
            }
          }
        }
      }
    }
  }




















}