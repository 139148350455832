$primary-color: #2b80ff;
$primary-color-back: #dbeaff;
$primary-linear-one: #4a77ee;
$primary-linear-two: #74acff;

.genius {
  .row-a1 {
    padding: 100px 0;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }

    .con-a1 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 100px;

      .pic {
        min-width: 500px;
        width: 500px;
        text-align: center;
        border-radius: 4px;
        overflow: hidden;
        img {
          border-radius: 4px;
          width: 100%;
        }
      }

      .desc {
        padding-left: 100px;
        .tit{
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 50px;
        }
        p {
          text-indent: 30px;
          margin-bottom: 20px;
          line-height: 30px;
        }
      }
    }

    .con-a2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 100px;
      .pic {
        min-width: 500px;
        width: 500px;
        text-align: center;
        overflow: hidden;
        img {
          border-radius: 4px;
          width: 100%;
        }
      }

      .desc {
        padding-right: 100px;
        .tit{
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 50px;
        }
        p {
          text-indent: 30px;
          margin-bottom: 20px;
          line-height: 30px;
        }
      }
    }
  }

  @media only screen and (max-width: 959px) {
    .row-a1 {
      .con-a1 {
        flex-direction: column;

        .pic {
          width: 100%;
          min-width: auto;
          margin-bottom: 100px;
        }

        .desc {
          padding: 0;
        }
      }

      .con-a2 {
        flex-direction: column;

        .pic {
          width: 100%;
          min-width: auto;
        }

        .desc {
          padding: 0;
          margin-bottom: 100px;
        }
      }
    }
  }


  @media only screen and (max-width: 500px) {
    .row-a1 {
      .title {
        margin-bottom: 100px;
      }
    }
  }
















}