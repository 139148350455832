@charset "utf-8";

* {
  box-sizing: border-box;
  outline: 0;
  padding: 0;
  margin: 0;
}

body {
  min-height: 100%;
  font-size: 14px;
  font-family: 'Microsoft Yahei';
  color: #333;
  overflow-x: hidden;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
fieldset,
lengend,
button,
input,
textarea,
th,
td {
  margin: 0;
  padding: 0;
}

ol,
ul,
li {
  list-style: none;
}


address,
caption,
cite,
code,
dfn,
em,
th,
var,
i {
  font-weight: normal;
  font-style: normal;
}

a {
  text-decoration: none;
  color: #333;
  display: inline-block;
  -webkit-transition-duration: .3s;
  -moz-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
}

select,
input,
textarea {
  border: 0;
  font-family: "Arial";
  outline: none;
  resize: none;
  box-shadow: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

select {
  background: #FFF;
}

input::-webkit-input-placeholder {
  color: rgb(160, 169, 186);
}

input::-moz-placeholder {
  color: rgb(160, 169, 186);
}

input:-moz-placeholder {
  color: rgb(160, 169, 186);
}

textarea::-webkit-input-placeholder {
  color: rgb(160, 169, 186);
}

textarea::-moz-placeholder {
  color: rgb(160, 169, 186);
}

textarea:-moz-placeholder {
  color: rgb(160, 169, 186);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
}

div,
dl,
dt,
dd,
ol,
ul,
li {
  zoom: 1;
}

caption,
th {
  text-align: left;
}

q:before,
q:after {
  content: '';
}

abbr,
acronym {
  border: 0;
}

input[type="button"],
input[type="submit"] {
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

input,
textarea {
  box-sizing: border-box;
}

input[type="text"] {
  -webkit-appearance: none;
}

textarea {
  -webkit-appearance: none;
}

/*文字对齐*/

.textL {
  text-align: left;
}

.textC {
  text-align: center;
}

.textR {
  text-align: right;
}

.fontWe {
  font-weight: 700;
}

/*强制换行   禁止换行*/

.break_word {
  word-wrap: break-word;
  word-break: break-all;
}

.keep_all {
  word-break: keep-all;
  white-space: nowrap;
}


/* 间距 */
.mtb30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mb50 {
  margin-bottom: 50px;
}

.pt10 {
  padding-top: 10px;
}

.pb40 {
  padding-bottom: 40px;
}

.pb60 {
  padding-bottom: 60px;
}

.plr60 {
  padding-left: 60px;
  padding-right: 60px;
}

.ptb60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.ptb70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

/* 宽度 */
.w160 {
  width: 160px;
}

.w1000 {
  width: 1000px;
}

.w180 {
  width: 180px;
}

.w280 {
  width: 280px;
}

.w860 {
  width: 860px;
}

.w960 {
  width: 960px;
}

.wid_a {
  width: 420px;
}

.wid_b {
  width: 210px;
}

.wid_c {
  width: 360px;
}

.col-fff {
  color: #fff !important;
}

.col-3d5 {
  color: #3d53ad !important;
}

/* border */
.bor-t {
  border-top: 1px solid #ededed;
}

.bor-b {
  border-bottom: 1px solid #ededed;
}

.bor-be8 {
  border-bottom: 1px solid #e8eaf5;
}

.ul-w3>li {
  width: calc(100% / 3);
  width: -webkit-calc(100% / 3);
  width: -moz-calc(100% / 3);
  float: left;
}

/* 标题 */
.new-top {
  padding: 60px 0px;
  text-align: center;
}

.new-tit {
  font-size: 36px;
  color: rgb(9, 17, 45);
}

/******* 状态 ******/

.fl {
  float: left;
}

.fr {
  float: right;
}

.pr {
  position: relative;
}

.pa {
  position: absolute;
}

.cont_flex {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.cont_flex_1 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
}

input[type="button"],
input[type="submit"],
input[type="reset"] {
  -webkit-appearance: none;
}

textarea {
  -webkit-appearance: none;
}