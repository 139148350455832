$primary-color: #2b80ff;
$primary-color-back: #dbeaff;
$primary-linear-one: #4a77ee;
$primary-linear-two: #74acff;

.recruit {
  .row-a1 {
    padding: 100px 0;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 32px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: #95b6db;
        font-weight: bolder;
        user-select: none;
      }
    }

    .back {
      background-color: #f0f7ff;
    }

    .con-a1 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .pic {
        width: 45%;
        text-align: center;
      }

      .text {
        width: 45%;

        .tit {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 20px;
        }

        .desc {
          font-weight: bold;
          margin-bottom: 20px;
        }

        .btn {
          width: 120px;
          line-height: 35px;
          background-color: #000;
          color: #fff;
          text-align: center;
          border-radius: 5px;
          font-size: 12px;
          transition: all .3s;

          &:hover {
            cursor: pointer;
            color: #000;
            background-color: #fff;
          }
        }
      }
    }

    .con {
      padding: 50px 0;

      &>.tit {
        text-align: center;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 50px;
      }

      &>.list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &>.li {
          width: 30%;
          position: relative;
          overflow: hidden;
          height: 350px;
          background-color: #bfd9f6;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0 0 5px 1px #a6a6a6;
          border-radius: 5px;

          .tit {
            font-size: 28px;
            color: #fff;
          }

          .shade {
            position: absolute;
            top: 100%;
            width: 100%;
            transition: all .3s;
            height: 100%;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;

            .list {
              display: flex;
              flex-direction: column;

              li {
                font-size: 24px;
                color: #000;
                list-style-type: disc;
              }
            }
          }

          &:hover {
            cursor: pointer;

            .shade {
              top: 0;
            }
          }
        }
      }
    }

  }

  @media only screen and (max-width: 960px) {
    .row-a1 {
      .con {
        .list {}
      }
    }
  }

  @media only screen and (max-width: 769px) {
    .row-a1 {
      .con-a1 {
        flex-direction: column;

        .pic {
          width: 100%;
          margin-bottom: 50px;
        }

        .text {
          width: 60%;
        }
      }

      .con {
        &>.list {
          flex-direction: column;

          &>.li {
            width: 80%;
            margin-bottom: 50px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .row-a1 {
      .con-a1 {
        .text {
          width: 100%;
          padding: 0 20px;
        }
      }
    }
  }










}