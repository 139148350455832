$primary-color: #2b80ff;
$primary-color-back: #dbeaff;
$primary-linear-one: #4a77ee;
$primary-linear-two: #74acff;

$primary-col: #00baad;

.cooperate {
  .row-a1 {
    background: url("../../static/images/pic023.png") no-repeat center;
    background-size: 100% 100%;
    padding: 100px 0;

    .title {
      margin-bottom: 50px;
      position: relative;

      .tit {
        font-size: 30px;
        text-align: center;
        position: relative;
        z-index: 9;
      }

      .txt {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 50px;
        color: $primary-color-back;
        font-weight: bolder;
        user-select: none;
      }
    }

    .con {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .con-a1 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .con-a2 {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        position: relative;
        top: -30px;
      }

      .li {
        position: relative;
        margin: 0 10px;
        transition: all 0.3s;
        width: 150px;
        height: 170px;

        &:hover {
          transform: scale(1.1);

          .icon {
            filter: brightness(150%);
          }
        }

        .back {
        }

        .item {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          .icon {
            height: 75px;
            line-height: 75px;
            text-align: center;
            img {
              height: 75px;
              width: 75px;
            }
          }
          .txt {
            line-height: 25px;
            font-weight: bold;
            text-align: center;
          }
        }
        &.opacity {
          opacity: 0.3;
        }
      }
    }
  }
  .desc {
    text-align: center;
    color: #2a82e4;
    font-weight: bold;
  }

  @media only screen and (max-width: 500px) {
    .row-a1 {
      .title {
        margin-bottom: 100px;
      }
      .con {
        .li {
          width: 45%;
          margin: 0 2.5%;
          margin-bottom: 10px;
          .item {
            .icon {
              height: 40px;
              line-height: 40px;
            }
            .txt {
            }
          }
        }
        .con-a1,
        .con-a2 {
          flex-wrap: wrap;
        }
      }
    }
  }
}
